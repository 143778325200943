<template>
  <div>
    <template v-if="message.sender === 'bot'">
      <div class="flex gap-[1.5rem] items-end">
        <img src="@/assets/goodbotProfile.png" alt="" class="w-[4.6875rem] h-[4.6875rem]" />
        <div
          class="bg-box001 min-h-[3.75rem] text-[1.375rem] shadow-gb rounded-[1rem] rounded-bl-none px-[1.5rem] py-[0.85rem] font-medium tracking-tight"
        >
          {{ message.content }}
        </div>
      </div>
    </template>
    <template v-if="message.sender === 'botInputing'">
      <div class="flex gap-[1.5rem] items-end">
        <img src="@/assets/goodbotProfile.png" alt="" class="w-[4.6875rem] h-[4.6875rem]" />
        <div
          class="bg-box001 min-h-[3.75rem] shadow-gb rounded-[1rem] rounded-bl-none px-[1.5rem] py-[0.85rem] font-medium tracking-tight"
        >
          <div class="flex w-full h-[1.5rem] items-center justify-center gap-[1rem]">
            <div
              style="animation-delay: 0"
              class="bg-gray-300 w-[1rem] h-[1rem] rounded-[1rem] shadow-md animate-bounce-slow"
            ></div>
            <div
              style="animation-delay: 300ms"
              class="bg-gray-300 w-[1rem] h-[1rem] rounded-[1rem] shadow-md animate-bounce-slow delay-[1000ms]"
            ></div>
            <div
              style="animation-delay: 600ms"
              class="bg-gray-300 w-[1rem] h-[1rem] rounded-[1rem] shadow-md animate-bounce-slow delay-[2000ms]"
            ></div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="message.sender === 'user'">
      <div class="flex justify-end">
        <div
          class="bg-primary min-h-[3.75rem] shadow-gb text-white text-[1.375rem] rounded-[1rem] rounded-br-none ml-[6.875rem] px-[1.5rem] py-[0.85rem] font-medium tracking-tight"
        >
          {{ message.content }}
        </div>
      </div>
    </template>
    <template v-if="message.sender === 'userInputing'">
      <div class="flex justify-end">
        <div
          id="userInputing"
          class="bg-primary min-h-[3.75rem] shadow-gb text-white text-[1.375rem] rounded-[1rem] rounded-br-none ml-[6.875rem] px-[1.5rem] py-[0.85rem] font-medium tracking-tight"
        >
          입력중...
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'MessageOutput',
  props: {
    message: Object
  }
}
</script>
