import { post, get } from './common'
import { API_BASE_URL } from '@/constants/setting'

const getNcpTtsUrl = (text) => {
  return API_BASE_URL + 'ncp/tts?language=kr&text=' + text;
}

const chatGoodjabi = async (text) => {
  return await post(
    'chat/goodjabi',
    {
      msg: text
    },
    true
  )
}

export { getNcpTtsUrl, chatGoodjabi }
