<template>
  <div class="home w-screen h-screen flex bg-box002">
    <div
      class="portrait:hidden w-[46.875%] relative"
      style="background: linear-gradient(151deg, #889cff 6%, #785dff 50%)"
    >
      <img
        src="@/assets/goodjabi.svg"
        alt="굿자비"
        class="absolute w-[36%] h-auto top-[9.26%] left-[8%]"
      />
      <img
        src="@/assets/bubble.svg"
        alt="굿자비"
        class="absolute w-[57.6%] h-auto top-[4.17%] right-[5.5%]"
      />
      <div
        class="w-full h-[72vh] -z-20 bg-box001 mt-[28vh] rounded-[5%_5%_0%_0%] shadow-[0_0_132px_0_rgba(0,0,0,0.25)] flex flex-col items-center"
      >
        <div
          class="mt-[14%] text-default002 text-[1.125rem] text-center z-10 bg-white rounded-[1rem] p-[1rem]"
        >
          <p class="font-semibold">
            아래 내용을 마이크에 대고 물어보면 음성과 화면으로 답을 확인할 수 있어요
          </p>
          <p>(직업계고 가이드북을 보며 물어보셔도 됩니다)</p>
        </div>
        <div
          class="flex flex-col items-center w-full pl-[6%] pr-[6.5%] mt-[2.5%] relative overflow-auto pb-[1rem]"
        >
          <div
            class="bg-primary w-full h-[3rem] rounded-[1rem] flex items-center px-[4.75%] py-[0.65rem] shadow-gb"
          >
            <p class="text-[1.625rem] font-semibold text-white">경북 직업계고에 대해 물어보세요</p>
          </div>
          <div class="w-full shadow-gb rounded-[1rem] px-[2.6875rem] py-[1.75rem] mt-[1rem]">
            <p class="text-primary002 text-[1rem] font-semibold pb-[0.8rem]">예시 질문</p>
            <div class="flex flex-col gap-[1.25rem]">
              <div class="flex items-center">
                <p class="text-primary text-[1.5rem] font-bold mr-[1.125rem]">Q.</p>
                <p class="font-medium text-[1.375rem]">경북에 특성화고는 몇 개 있나요?</p>
              </div>
              <div class="flex items-center">
                <p class="text-primary text-[1.5rem] font-bold mr-[1.125rem]">Q.</p>
                <p class="font-medium text-[1.375rem]">경북기계금속고 학과는 무엇이 있나요?</p>
              </div>
            </div>
          </div>
          <div
            class="bg-primary w-full h-[3rem] rounded-[1rem] flex items-center px-[4.75%] py-[0.65rem] shadow-gb mt-[2rem]"
          >
            <p class="text-[1.625rem] font-semibold text-white">
              경북 직업교육 성과에 대해 물어보세요
            </p>
          </div>
          <div class="w-full shadow-gb rounded-[1rem] px-[2.6875rem] py-[1.75rem] mt-[1rem]">
            <div class="flex flex-col gap-[1.25rem]">
              <div class="flex items-center">
                <p class="text-primary text-[1.5rem] font-bold mr-[1.125rem]">Q.</p>
                <p class="font-medium text-[1.375rem]">경북교육청 2023년 취업률은?</p>
              </div>
            </div>
          </div>
          <img src="@/assets/logo.svg" alt="굿자비" class="mt-[2.5rem] w-[5.5vmax]" />
        </div>
      </div>
    </div>
    <div
      class="portrait:w-full landscape:w-[53.125%] z-0 flex flex-col justify-between bg-box002 portrait:px-[2rem] px-[6.25rem] py-[4rem]"
    >
      <template v-if="messagesState.length !== 0">
        <div
          ref="innerChatElement"
          class="flex flex-col gap-[3.125rem] h-[75%] py-[0.1rem] pr-[0.3rem] overflow-auto"
          id="innerChatElement"
        >
          <MessageOutput
            v-for="(message, idx) in messagesState"
            :key="idx"
            :message="
              message.content == '입력중...' && lastMessage != ''
                ? { sender: 'user', content: lastMessage }
                : message
            "
          ></MessageOutput>
        </div>
      </template>
      <div class="flex justify-center items-center gap-[16px] relative">
        <input
          type="text"
          v-model="lastMessage"
          @keyup.enter="sendMessage"
          @input="onTextChange"
          :disabled="listening === true || loading === true"
          class="enabled:shadow-sk h-[71px] w-full text-h3-semibold rounded-[40px] px-[30px] border border-sk-border focus:outline-sk-primary"
        />
        <div>
          <button v-if="lastMessage.length !== 0" @click="sendMessage">
            <img src="@/assets/chat/send.svg" />
          </button>
          <button v-else @click="switchSTT" class="relative">
            <img src="@/assets/chat/mic.svg" />
            <img
              v-if="listening === true"
              src="@/assets/chat/blue.svg"
              class="absolute top-0 right-0 -z-10 animate-ping-slow"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageOutput from '@/components/MessageOutput.vue'
import { nextTick } from 'vue'
import { getNcpTtsUrl, chatGoodjabi } from '@/apis/chatApi.js'
var messageObj = {
  listening: false,
  recognition: undefined,
  message: ''
  //length: 0
}
export default {
  name: 'HomeView',
  components: { MessageOutput },
  data() {
    return {
      messagesState: [
        {
          sender: 'bot',
          content: '안녕하세요. AI로봇 굿자비에요!'
        }
      ],
      loading: false,
      botTyping: false,
      listening: messageObj.listening,
      snd: undefined,
      lastMessage: ''
    }
  },

  mounted() {
    window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
    messageObj.recognition = new SpeechRecognition()
    messageObj.recognition.lang = 'ko-KR'
    messageObj.recognition.interimResults = false
    messageObj.recognition.continuous = false
    messageObj.recognition.onend = function () {
      if (messageObj.listening === true) {
        messageObj.recognition.start()
        //console.log(messageObj.listening)
      }
      //console.log('onend')
    }
    messageObj.recognition.onresult = function (event) {
      //console.log('onresult')
      messageObj.message += ' ' + event.results[0][0].transcript
      //console.log(messageObj.message)
      let userInputingTag = document.querySelector('#userInputing')
      userInputingTag.innerText = messageObj.message
      let innerChatElement = document.querySelector('#innerChatElement')
      innerChatElement.scrollTo({
        top: innerChatElement.scrollHeight,
        behavior: 'smooth'
      })
    }
  },
  methods: {
    scrollBottom() {
      nextTick(() => {
        const innerChatElement = this.$refs.innerChatElement
        innerChatElement.scrollTo({
          top: innerChatElement.scrollHeight,
          behavior: 'smooth'
        })
      })
    },
    switchSTT() {
      if (messageObj.listening === false) {
        if (this.loading === true) {
          return
        }
        messageObj.listening = true
        this.listening = true
        if (this.snd != undefined) {
          this.snd.pause()
        }
        messageObj.recognition.start()
        this.messagesState.push({
          sender: 'userInputing',
          content: '입력중...'
        })
        //messageObj.length += 1
        this.scrollBottom()
      } else {
        //console.log('false')
        messageObj.listening = false
        this.listening = false
        messageObj.recognition.abort()
        this.submitMessage()
      }
    },
    async submitMessage() {
      const _message = messageObj.message.trim()
      messageObj.message = ''
      this.lastMessage = ''
      this.messagesState.pop()
      if (_message === '') {
        return
      }
      this.messagesState.push({
        sender: 'user',
        content: _message
      })
      //messageObj.length += 1
      this.loading = true
      this.messagesState.push({
        sender: 'botInputing',
        content: ''
      })
      this.scrollBottom()
      const chatRes = await chatGoodjabi(_message)
      this.messagesState.pop()
      this.loading = false
      if (chatRes.status === 200) {
        this.messagesState.push({
          sender: 'bot',
          content: chatRes.answer
        })
        this.scrollBottom()
        var chatText = chatRes.answer.trim()
        // replace all '%' in chatText into '퍼센트' to prevent error in ncp tts api
        chatText = chatText.replaceAll('%', '퍼센트')
        var sndUrl = getNcpTtsUrl(chatText)
        //console.log(sndUrl)
        this.snd = new Audio(sndUrl)
        await this.snd.play()
      } else {
        this.messagesState.push({
          sender: 'bot',
          content: '오류가 발생했습니다. 다시 시도해주세요.'
        })
      }
      messageObj.length += 1
      this.scrollBottom()
    },
    async sendMessage() {
      const _message = this.lastMessage.trim()
      this.lastMessage = ''
      if (_message === '' || this.loading === true) {
        return
      }
      if (this.snd != undefined) {
        this.snd.pause()
      }
      this.messagesState.push({
        sender: 'user',
        content: _message
      })
      //messageObj.length += 1
      this.loading = true
      this.messagesState.push({
        sender: 'botInputing',
        content: ''
      })
      this.scrollBottom()
      const chatRes = await chatGoodjabi(_message)
      this.loading = false
      if (chatRes.status === 200) {
        this.messagesState.pop()
        this.messagesState.push({
          sender: 'bot',
          content: chatRes.answer
        })
        this.scrollBottom()
        var chatText = chatRes.answer.trim()
        // replace all '%' in chatText into '퍼센트' to prevent error in ncp tts api
        chatText = chatText.replaceAll('%', '퍼센트')
        var sndUrl = getNcpTtsUrl(chatText)
        //console.log(sndUrl)
        this.snd = new Audio(sndUrl)
        await this.snd.play()
      } else {
        this.messagesState.pop()
        this.messagesState.push({
          sender: 'bot',
          content: '오류가 발생했습니다. 다시 시도해주세요.'
        })
      }
      messageObj.length += 1
      this.scrollBottom()
    },
    // update this.messagesState last message content using messageObj.message
    updateMessage(newMessage) {
      this.messagesState[this.messagesState.length - 1].content = newMessage
    },
    onTextChange(e) {
      this.lastMessage = e.target.value
    }
  }
}
</script>
